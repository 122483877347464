import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import HeaderMenu from './headerMenu'
import Footer from './footer'
import Helmet from 'react-helmet'
import ogImageMain from '../assets/images/1200x628-logo-banner.jpg'

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `)

  const isIndexPage = data.site.siteMetadata.siteUrl === "/";

  return (
    <main>
      <Helmet
        bodyAttributes={{
          class: 'pages-custom'
        }}
      />

    <Helmet>
      <meta property="fb:app_id" content='2970238806604187' />
      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={data.site.siteMetadata.title} />
      <meta property='og:description' content={data.site.siteMetadata.description} />
      <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}` + `${ogImageMain}`} />
    </Helmet>

      <HeaderMenu isIndexPage={true} />

      {/* <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <p className={siteTitle}>{data.site.siteMetadata.title}</p> */}
      <div className='px-5'>
        <div className='text-sm'>
          <div className="container mx-auto py-20 md:py-24">
            {pageTitle ? 
                <h1 className="w-full inline-block text-2xl font-semibold lg:text-3xl leading-8 lg:max-w-2xl lg:leading-12 tracking-normal md:font-semibold drop-shadow-sm py-5 mb-5 md:my-8 text-black">{pageTitle}</h1>
              : 
                null
            }
            <div className=''>
                {children}  
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default Layout